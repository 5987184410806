import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Stay tuned" />
    {/* <h1 style={{
      textAlign: 'center'
    }}>
      VH
    </h1> */}
    <h2>Coming soon</h2>
    {/* <p>Welcome to your new Gatsby site.</p> */}
    {/* <p>Now go build something great.</p> */}
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
